import {
  imageAdapter,
  imageUrlAdapter,
} from '@/adapters/constructor/utils/image';
import { linkAdapter } from '@/adapters/constructor/utils/link';

export default ({ data }) => {
  const { registration } = data;

  return {
    name: data.name,
    title: data.title,
    link: linkAdapter(data.link),
    text: data.text,
    subtext: data.subtext,
    banner: imageAdapter(data.banner, data.banner_mobile),
    qr: imageUrlAdapter(data.qr?.image),
    registrationActive: registration.active,
    registrationClosed: {
      title: registration.reg_closed_title,
      text: registration.reg_closed_text,
    },
  };
};
